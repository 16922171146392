import React from 'react'
import { navigate } from 'gatsby'
import { FiSend } from 'react-icons/fi'

import './form.scss'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        className="contact-form"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>
        <input
          id="name"
          type="text"
          name="name"
          placeholder="Name"
          required
          onChange={this.handleChange}
        />
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Email"
          required
          onChange={this.handleChange}
        />
        <input
          id="phonenumber"
          type="text"
          name="phonenumber"
          placeholder="Phone Number"
          onChange={this.handleChange}
        />
        <textarea
          id="message"
          name="message"
          rows="5"
          placeholder="How can we help?"
          required
          onChange={this.handleChange}
        />
        <p>
          Hassle Free Booking &mdash;{' '}
          <span className="notice">
            we will NOT spam/solicit/redistribute your details
          </span>
        </p>
        <button className="button" type="submit">
          {this.props.button || (
            <>
              <FiSend /> Send
            </>
          )}
        </button>
      </form>
    )
  }
}
