import React from 'react'
import { Link } from 'gatsby'
import { FiPhoneIncoming, FiCalendar, FiPhone } from 'react-icons/fi'

import './cta.scss'

/*
 * Call to Action
 */
export default () => (
  <div className="cta cta-main ribbon ribbon-no-mobile ribbon-full-width">
    <Link className="button" to="/contact-us#email">
      <FiCalendar /> Book an Appointment
    </Link>
    <Link className="button" to="/contact-us#email">
      <FiPhoneIncoming /> Request a Call-back
    </Link>
    <Link className="button" to="/contact-us">
      <FiPhone /> Talk to us now!
    </Link>
  </div>
)
