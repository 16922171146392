import React from 'react'
import Helmet from 'react-helmet'

import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Img from 'gatsby-image'

import Layout from 'src/components/layout'
import Row from 'src/components/row'

import { FiCalendar } from 'react-icons/fi'
import Form from 'src/components/form'
import CTA from 'src/components/cta'

export default ({ data }) => (
  <Layout>
    <div className="content">
      <Helmet>
        <title>
          Backflow Prevention - {data.site.siteMetadata.title}
        </title>
      </Helmet>
      <Slider
        autoplay
        speed={500}
        slidesToScroll={1}
        slidesToShow={3}
        arrows={false}
        variableWidth
        adaptiveHeight
        responsive={[{breakpoint: 480, settings: {slidesToShow: 1}}]}
      >
        {data.images.edges.map(({node: { childImageSharp: { fixed } }}) => (
          <div key={fixed.src}>
            <Img fixed={fixed}  style={{margin: "10px"}}/>
          </div>
        ))}
      </Slider>

      <Row columns={3}>
        <div className="frame">
          <div className="banner">Book Appointment Today</div>
          <div class="frame-content">
            <Form
              button={
                <>
                  <FiCalendar /> Book an Appointment
                </>
              }
            />
          </div>
        </div>
        <div style={{flexBasis: '66%'}}>
          <h1 style={{lineHeight: '1.2em'}}>We Sell, Install, Test, Repair and Maintain Backflow Preventers</h1>
          <p>
            Call or contact us today and we will have an authorized person (as
            per Schedule 6, of Municipal Code Chapter 851) ensure the job is done
            right.  We employ AWWA and OWWA certified technicians to backflow
            test, repair, install and ensure compliance with by-laws.
          </p>
          <p>
            Have you received a warning letter from the City of Toronto, Region
            of Peel, Halton Region, Town of Markham or other municipality
            demanding that you test or install a backflow preventer or check valve
            at your commercial, residential, or multi-unit property? Are you
            concerned about the quality of your water supply, and want to protect
            yourself from the liability for cross-contamination? Do you need a
            backflow preventer device or flow control valve installed or tested, or
            a cross-contamination solution installed? If so contact us now.
          </p>
        </div>
      </Row>

      <div>
        <h2>Why did I get this letter?</h2>
        <p>
          All municipalities are working on
          safeguarding their drinking water by requiring that all residential,
          Commercial, Industrial and Institutional buildings have the proper
          protections in place on their incoming water lines.  This is
          accomplished through the installation of backflow prevention devices.
          They are essentially water valves that let water pass only one way,
          into the building, and prevent interior building piping from flowing
          back into the city mains potentially causing contamination.
        </p>
        <h2>What is Backflow?</h2>
        <p>
          Backflow the undesired reverse flow of contaminants
          into the potable water and is typically driven by common, but
          unfavorable, hydraulic events in either the public or private water
          distribution system.
        </p>
        <p>
          Backflow preventers are devices used to stop this from happening, and
          protects our drinking water lines.
        </p>

        <h1>What now? The process:</h1>
        <h2 style={{textAlign: "center", paddingTop: "2rem"}}>
          Do I have proper protection on my water connections?
        </h2>
        <Row columns={2}>
          <div>
            <h2>Yes!</h2>
            <p>
              Great, call us today to have your existing backflow prevention
              device tested. We will ensure that it is in good working order.
              We also handle all the municipal reporting and paperwork.
            </p>
          </div>
          <div>
            <h2>I don't know?</h2>
            <p>
              Call us today to have one of our expers survey your facility.
            </p>
            <p>
              We will advise you on next steps and create a plan to ensure
              proper protections are put in place according to requirements set
            out by your municipality.
            </p>
          </div>
        </Row>

      </div>
    </div>
    <CTA />
    <div className="content">
      <div>

        <h2>Is ARC Qualified to do our backflow preventer testing or carry out our cross connection survey?</h2>
        <p><strong><u><big>YES we ARE!</big></u></strong></p>

        <ul>
          <li>
            We are qualified, certified and registered to test, install, service and/or repair Backflow preventers.
          </li>
          <li>
            We are experienced in working with various municipalities in Ontario and will ensure  the backflow prevention test report(s) and any related paperwork is completed and submitted properly.
          </li>
          <li>
            We are able to complete and submit all paperwork for the purposes of completing a cross connection survey at your facility
          </li>
          <li>
            We have OWWA (Ontario Waterworks Association) certified testers
          </li>
          <li>
            We hire Licensed Plumbers
          </li>
          <li>
            We are authorized (as per Schedule 6, of Municipal Code Chapter 851)  to carry work out in your municipality – City of Toronto, Region of Peel, Halton Region, London, etc.
          </li>
          <li>
            We take care of the paperwork for you – municipal reporting
          </li>
          <li>
            We track all testing in our database and will send you an annual reminder when your device is up for annual testing.
          </li>
        </ul>

        <h2>Our commitment to you – We work with Trusted Manufacturers</h2>
        <ul>
          <li>
          We sell and install Watts Backflow Preventers, ZURN Wilkins Backflow Preventers and backflow preventers from other leading manufacturers. Let ARC be your certified backflow tester.
          </li>
          <li>
          When and if devices fail, we offer repair services and can often rebuild them
          </li>
        </ul>


      </div>

    </div>
  </Layout>
)

export const query = graphql`
  query CrossConnectionSurveyQuery {
    site {
      siteMetadata {
        title
      }
    }

    images: allFile(filter: { relativeDirectory: { regex: "/backflow-prevention-slideshow/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(height: 300, fit: INSIDE) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
